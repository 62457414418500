import React, { useEffect, useState, useRef } from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { isBrowser } from "../../../context/ApolloContext"
import { injectIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import BlankStar from "../../../../static/blank_star.png"
import GreenStar from "../../../../static/green_star.png"
import HalfStar from "../../../../static/half_star.png"
import { List, MicroStar } from "@dadoagency/gatsby-plugin-trustpilot-widget"
const $ = isBrowser ? require("jquery") : {}
const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const flickityOptions = {
  freeScroll: false,
  pageDots: true,
  wrapAround: true,
  prevNextButtons: true,
}

const ReviewSlider = ({ intl }) => {
  var prevNext = useRef()
  const [currData, setcurrData] = useState([])

  const data = useStaticQuery(graphql`
    query ReviewSliderQuery {
      allDirectusTrustpilotReview {
        nodes {
          language
          name
          review
          status
          stars
          title
        }
      }
    }
  `)

  const setData = () => {
    let slider = []
    if (data && data.allDirectusTrustpilotReview.nodes.length > 0) {
      data.allDirectusTrustpilotReview.nodes.forEach(item => {
        if (intl.locale === item.language.toLowerCase()) {
          slider.push(item)
        }
      })
    }
    setcurrData(slider)
  }

  const starCounter = data => {
    let stars = []
    let blank_stars = 5 - data
    for (let i = 0; i < data; i++) {
      stars.push(
        <Image
          key={"fill" + i.toString()}
          className="lazyload"
          data-src={GreenStar}
          alt=""
        />
      )
    }
    if (blank_stars >= 1) {
      for (let i = 0; i < blank_stars; i++) {
        stars.push(
          <Image
            key={"blank" + i.toString()}
            className="lazyload"
            data-src={BlankStar}
            alt=""
          />
        )
      }
    }
    return stars
  }

  const mobileimageCounter = data => {
    let mobile_slider = []
    for (let i = 0; i < data.length; i = i + 1) {
      mobile_slider.push(
        <Row key={i.toString()}>
          {data[i] && (
            <Card>
              {data[i].stars && <StarBox>{starCounter(data[i].stars)}</StarBox>}
              <TextBox>
                {data[i].title && (
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: data[i].title,
                    }}
                  />
                )}
                {data[i].review && (
                  <ReviewText
                    dangerouslySetInnerHTML={{
                      __html: data[i].review,
                    }}
                  />
                )}
                {data[i].name && (
                  <Name
                    dangerouslySetInnerHTML={{
                      __html: data[i].name,
                    }}
                  />
                )}
              </TextBox>
            </Card>
          )}
        </Row>
      )
    }
    return mobile_slider
  }

  useEffect(() => {
    setData()
  }, [intl.locale])

  const iframeCustomStyle = () => {
    $(document).ready(function() {})
  }

  useEffect(() => {
    isBrowser && iframeCustomStyle()
  }, [])

  return (
    <TrustPilotSliderWrapper>
      <Wrapper>
        {isBrowser && (
          <Carousel className="carousel">
            <DesktopSlider style={{ width: "100%" }}>
              <Flickity
                flickityRef={c => (prevNext = c)}
                options={flickityOptions}
                className={"carousel"}
              >
                {mobileimageCounter(currData).map(item => item)}
              </Flickity>
            </DesktopSlider>
            <MobileSlider style={{ width: "100%" }}>
              <Flickity
                flickityRef={c => (prevNext = c)}
                options={flickityOptions}
                className={"carousel"}
              >
                {mobileimageCounter(currData).map(item => item)}
              </Flickity>
            </MobileSlider>
          </Carousel>
        )}
      </Wrapper>
    </TrustPilotSliderWrapper>
  )
}

export const TrustPilotSliderWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 20px 15px;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  margin: 20px 0px;
  border-radius: 50px 0px 50px 0px;
  padding: 25px 25px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  @media (max-width: 850px) {
    margin-bottom: 60px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-family: "Gotham";
`

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`

export const DesktopSlider = styled.div`
  width: 100%;
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileSlider = styled.div`
  width: 100%;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  object-fit: contain;
  flex-direction: row;
  padding: 0px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
`

export const StarBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  object-fit: contain;
  margin-bottom: 10px;
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0px 1px;
  max-width: 23px;
`

export const TextBox = styled.div`
  margin: 0;
  border-radius: 10px;
  text-align: left;
`

export const Title = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;
  min-height: 32px;
  overflow: hidden;
`

export const ReviewText = styled.div`
  font-family: "Gotham Book";
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 10px 0px;
  min-height: 90px;
  overflow: hidden;
  line-height: 1.2;

  @media (min-width: 1600px) {
    min-height: 60px;
  }

  @media (max-width: 767px) {
    min-height: 60px;
  }
`

export const Name = styled.div`
  font-family: "Gotham Book";
  font-size: 14px;
  font-weight: 500;
  color: #262626;
`

export const Carousel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  padding: 0px 30px;

  @media (max-width: 850px) {
    padding: 0px;
  }

  .flickity-slider {
    display: flex;

    @media (max-width: 767px) {
      align-items: center;
    }
  }

  .flickity-prev-next-button {
    background-color: transparent;
    color: #111;
    width: 36px;
    height: 36px;
    border: 2px solid #111;
    top: 100%;

    :hover {
      background-color: #111;
      color: #fff;
    }
  }
  .flickity-prev-next-button {
    top: 50%;
    transform: translate(0%, -50%);

    @media (max-width: 850px) {
      bottom: -90px;
      top: auto;
    }
  }
  .flickity-prev-next-button.next {
    right: -40px;

    @media (max-width: 850px) {
      right: -35px;
    }

    @media (max-width: 850px) {
      right: -25px;
    }
  }
  .flickity-prev-next-button.previous {
    left: -40px;

    @media (max-width: 850px) {
      left: -35px;
    }

    @media (max-width: 850px) {
      left: -25px;
    }
  }
  .flickity-page-dots {
    bottom: -15px;
    max-width: 170px;
    margin: 0px auto;
    left: 0;
    right: 0;

    @media (max-width: 850px) {
      bottom: -63px;
    }
  }
  .flickity-page-dots .dot {
    margin: 0 2px;

    @media (max-width: 550px) {
      background: #fff;
    }
  }
`

export default injectIntl(ReviewSlider)
